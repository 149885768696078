import BannerImage2 from "../img/Kazusa-rollsheeeep-21_07_2024.jpg"

export default function Strength ({resultAboutUs, styles, en = false}) {

  const BgColor = styles.primaryColor ? `bg-[${styles.primaryColor}]` : `bg-blue-900`
  const FromColor = styles.primaryColor ? `from-[${styles.primaryColor}]` : `from-blue-900`

  return (
    <>
      <div className="h-screen flex flex-row items-center bg-cover bg-center overflow-x-hidden" style={{backgroundImage: `url(https://cmsfg.fitekservice.my.id/assets/images/aboutUs/${resultAboutUs.banner_2})`}}>
        <div data-aos="fade-right" className={`${BgColor} lg:bg-transparent lg:bg-gradient-to-r ${FromColor} lg:from-75% lg:to-transparent lg:to-75% lg:basis-2/3 h-2/3 flex flex-row items-center justify-center text-white`}
        style={{
          backgroundImage: `linear-gradient(to right, ${styles.primaryColor} 75%, transparent 75%)`
        }}
        >
          <div className="basis-1/2 px-8">
            <h2 className="text-5xl font-bold my-4">
              { en ? 'Strength' : 'Keunggulan'}
            </h2>
            <p className="text-justify" dangerouslySetInnerHTML={{__html: resultAboutUs.featured}}>
            </p>
          </div>
          <div className="basis-1/2 hidden lg:flex lg:flex-col gap-y-4 justify-center items-center text-black h-full">
            <div className="font-bold text-xl bg-white w-2/3 h-1/3 flex flex-col justify-center">
              <p className="text-center">
              {resultAboutUs.total_project ?? 0}
              </p>
              <p className="text-center">
                { en ? 'Satisfied Client' : 'Client Senang'}
              </p>
            </div>
            <div className="font-bold text-xl bg-green-400  w-2/3 h-1/3 flex flex-col justify-center">
              <p className="text-center">
              {resultAboutUs.total_project ?? 0}
              </p>
              <p className="text-center">
                { en ? 'Total Client' : 'Jumlah Client'}
              </p>
            </div>
          </div>
        </div>
        
        <div data-aos="fade-left" className="text-white text-5xl text-center font-bold hidden lg:flex lg:flex-row lg:justify-center basis-1/3">
          <div>
            <h2 className="text-center">
            { en ? 'With Fhadira Group' : 'Bersama Fhadira Group' }
            </h2>
            <h2 className="text-center">
            { en ? 'Build a Nation' : 'Membangun Negeri' }
            </h2>
          </div>
        </div>

      </div>
    </>
  )
}