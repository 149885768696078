import React from 'react';
import { FaEnvelope, FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import './footer-fga.css';

const FooterFGMembers = ({ backgroundColor, fb, twitter, instagram, linkedin, email }) => {
  return (
    <footer className="footer" style={{ '--bg-color': backgroundColor }}>
      <div className="social-icons">
        <hr className='separator' />

        <span className='social-icon'>
          <FaFacebook size={25} />
        </span>
        <span className='social-icon' onClick={() => {window.location.href = `https://www.x.com/${twitter}` }}>
          <FaTwitter size={25}/>
        </span>
        <span className='social-icon' onClick={() => {window.location.href = `https://www.instagram.com/${instagram}` }}>
          <FaInstagram size={25}/>
        </span>
        <span className='social-icon' onClick={() => {window.location.href = `https://www.linkedin.com/in/${linkedin}` }}>
          <FaLinkedin size={25}/>
        </span>
        <span className='social-icon' onClick={() => {window.location.href = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=SUBJECT&body=BODY&bcc=${email}` }}>
          <FaEnvelope size={25}/>
        </span>
        <hr className='separator' />
      </div>
      <div>
        <img src="/assets/logo-fitek-footer.png" alt="logo-fitek-footer" width={150} />
      </div>
      <p className="copyright">Copyright &copy; 2024 Fitek</p>
    </footer>
  );
};

export default FooterFGMembers;
