
import LinkedinImage from "../img/Compro FG/linkedin_blue.svg"
import TwitterImage from "../img/Compro FG/twitter_blue.svg"
import FacebookImage from "../img/Compro FG/facebook_blue.svg"
import InstagramImage from "../img/Compro FG/instagram_blue.svg"
import YoutubeImage from "../img/Compro FG/youtube_blue.svg"

export default function NewsInformation({resultContactUs, resultNews}){

  const InstagramLink = `https://www.instagram.com/${resultContactUs.instagram}`
  const TwitterLink = `https://www.x.com/${resultContactUs.twitter}`
  const LinkedinLink = `https://www.linkedin.com/in/${resultContactUs.linkedin}`  

  // console.log(resultNews);
  

  return (
    <>
      <div className="min-h-screen py-4 w-4/5 mx-auto flex flex-col gap-y-4">
        <h2 className="mt-16 text-center font-bold text-3xl">
          {resultNews.name}
        </h2>
        <div className="flex flex-row justify-center">
          <img src={`https://cmsfg.fitekservice.my.id/assets/images/news/${resultNews.file}`} alt="" className="aspect-video w-full" />
        </div>
        <div className="flex flex-row justify-between">
          <div>
            <p className="text-center text-sm">
              {resultNews.created_at}
            </p>
          </div>
          <div className="flex flex-row items-center gap-x-4">
            <span>
              Bagikan :
            </span>
            <a href={InstagramLink} target="_blank" className="hover:opacity-50">
              <img src={InstagramImage} alt="" />
            </a>
            <a href={InstagramLink} target="_blank" className="hover:opacity-50">
              <img src={FacebookImage} alt="" />
            </a>
            <a href={LinkedinLink} target="_blank" className="hover:opacity-50">
              <img src={LinkedinImage} alt="" />
            </a>
            <a href={InstagramLink} target="_blank" className="hover:opacity-50">
              <img src={YoutubeImage} alt="" />
            </a>
            <a href={TwitterLink} target="_blank" className="hover:opacity-50">
              <img src={TwitterImage} alt="" />
            </a>
          </div>
        </div>
        <div dangerouslySetInnerHTML={{__html: resultNews.description}}>
        </div>
      </div>
    </>
  )
}