// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { useState } from 'react';

// Import Swiper styles
import 'swiper/css';
import Modal from './Modal';

export default function Portfolio({resultPorto, en = false, styles}){

  const portfolio = en ? 'Portfolio' : 'Portofolio';

  const isMobile = window.innerWidth < 768;

  const [isHovered, setIsHovered] = useState(false);
  const [hoverIndex, setHoverIndex] = useState(null);
  const [hoveredImage, setHoveredImage] = useState(null);
  const [hoveredTitle, setHoveredTitle] = useState(null);
  const [hoveredCat, setHoveredCat] = useState(null);
  const [hoveredText, setHoveredText] = useState(null);

  const handleMouseEnter = (index, image, title, cat, text) => {
    setIsHovered(true);
    setHoverIndex(index);
    setHoveredImage(image);
    setHoveredTitle(title);
    setHoveredCat(cat);
    setHoveredText(text);
    console.log('hover triggered, index: ' + index);
  };

  const handleMouseLeave = (index) => {
      setIsHovered(false);
      setHoverIndex(index);
      setHoveredImage(null);
      setHoveredTitle(null);
      setHoveredText(null);
      setHoveredCat(null);
      console.log('mouse leave, index: ' + index);
  };

  return (
    <>
      <div data-aos="fade-right" id='portfolio' className="h-screen py-4 overflow-x-hidden">
        <div className="h-full flex flex-col justify-center w-5/6 mx-auto">
          <h2 className="text-center font-bold text-3xl my-4">
            Portfolio
          </h2>
          <div>
            <Swiper
              spaceBetween={50}
              slidesPerView={isMobile ? 1 : 3}
              // onSlideChange={() => console.log('slide change')}
              // onSwiper={(swiper) => console.log(swiper)}
            >
              {
                  resultPorto.map((porto, index) => (
                      <SwiperSlide                               
                      // onClick={() => handleMouseEnter(index, `https://cmsfg.fitekservice.my.id/assets/images/products/${porto.file}`, porto.name, `${porto.category.name}, ${porto.sub_category.name}`, en ? porto.description_en : porto.description)}
                      // onMouseLeave={() => handleMouseLeave(null)}
                      >
                        <div>
                          <Modal porto={porto} en={en} styles={styles}></Modal>
                          {/* <img src={`https://cmsfg.fitekservice.my.id/assets/images/products/${porto.file}`} alt="" className='aspect-square object-cover'/> */}
                        </div>
                      </SwiperSlide>  
                  ))
              }
            </Swiper>
          </div>
          {hoveredImage && (
            <div className="h-screen text-white">
              <div className="flex flex-row">
                <div className="">
                    <img src={hoveredImage} alt="popup-image" style={{ width: "800px", height: "400px",  objectFit: 'cover' }} />
                </div>
                <div className="flex flex-col justify-center px-20 gap-y-4" style={{ backgroundColor: styles.primaryColor  }}>
                  <h3 className='text-3xl'>{hoveredTitle}</h3>
                  <p className=''><b>{hoveredCat}</b></p>
                  <div className=''dangerouslySetInnerHTML={{__html: hoveredText}}></div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}