import React, { useEffect, useState } from 'react'
// import Header from '../../../../components/header/id/header'
// import { Row, Col, Carousel, Image, Button } from 'react-bootstrap'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ScrollToTop from "react-scroll-to-top";
// import Faq from '../../component/faq';
// import Footer from '../../component/footer';
// import News from '../../component/news';
// import Strength from '../../component/strength';
// import Visimisi from '../../component/visimisi';
// import Testimony from '../../component/testimony';
// import Portfolio from '../../component/portfolio';
// import AboutUs from '../../component/aboutus';
import Navbar from "../../component/Header"
import Banner from "../../component/Banner"
import AboutUs from "../../component/AboutUs"
import Strength from "../../component/Strength"
import Portfolio from "../../component/Portfolio"
import News from "../../component/News"
import Faq from "../../component/Faq"
import VisionMission from "../../component/VisionMission"
import Testimony from "../../component/Testimony"
import Footer from "../../component/Footer"
import { FaWhatsapp } from 'react-icons/fa';

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init({
    duration: 500, // values from 0 to 3000, with step 50ms
    delay: 100, // values from 0 to 3000, with step 50ms
});

const api = axios.create({
    baseURL: `https://cmsfg.fitekservice.my.id/api`
})
const MembersLandingPage = () => {
    const [result, setResult] = useState([]);
    const [resultAboutUs, setResultAboutUs] = useState([]);
    const [resultArticles, setResultArticles] = useState([]);
    const [resultTestimonies, setResultTestimonies] = useState([]);
    const [resultPorto, setResultPorto] = useState([]);
    const [resultContactUs, setResultContactUs] = useState([]);
    const [resultFaq, setResultFaq] = useState([]);
    const [showFullDescription, setShowFullDescription] = useState(false);
    let { namapartner } = useParams();
    const styles = {
        primaryColor: `${result.primary_color}`,
        secondaryColor: `${result.secondary_color}`,
        // ... other styles
    };
    const itemStyle = {
        width: '413px', // Atur lebar
        height: '413px', // Atur tinggi
    };
    const [isHovered, setIsHovered] = useState(false);
    const [banner2, setBanner2] = useState(null);

    const showFullDescriptionHandler = () => {
        setShowFullDescription(!showFullDescription);
    };
    const buttonStyles = {
        color: "blue", // Customize the button color
        // Add any other CSS styles as needed
        background: "transparent",
        border: "none"
    };
    const customColStyle = {
       
    };
    const isMobile = window.innerWidth < 768;
    useEffect(() => {
        {
            isHovered && (
                <div className="text-overlay">
                    <p className='poppins-medium'>Ini adalah teks yang ditampilkan saat gambar di-hover.</p>
                </div>
            )
        }
        // Fungsi untuk memanggil API
        const fetchData = async () => {
            try {
                namapartner = namapartner ?? 'Fitek';
                // console.log('nama partner: ' + namapartner);
                const response = await api.get('/partners?safety_key=M1n0r_P34c3_0f_C4l4mity&whitelabel= ' + `${namapartner}`); // Ganti '/endpoint' dengan path API yang sesuai
                setResult(response.data.data); // Mengatur hasil response ke state
                // console.log(response.data);

                const responseAboutUs = await api.get('about-us?safety_key=M1n0r_P34c3_0f_C4l4mity&whitelabel=' + `${namapartner}`); // Ganti '/endpoint' dengan path API yang sesuai
                setResultAboutUs(responseAboutUs.data.data); // Mengatur hasil response ke state
                // console.log(responseAboutUs.data);

                const responseArticles = await api.get('articles?safety_key=M1n0r_P34c3_0f_C4l4mity&whitelabel=' + `${namapartner}`); // Ganti '/endpoint' dengan path API yang sesuai
                setResultArticles(responseArticles.data.data); // Mengatur hasil response ke state
                // console.log(responseArticles.data);

                const responseTestimonies = await api.get('testimonies?safety_key=M1n0r_P34c3_0f_C4l4mity&whitelabel=' + `${namapartner}`); // Ganti '/endpoint' dengan path API yang sesuai
                setResultTestimonies(responseTestimonies.data.data); // Mengatur hasil response ke state
                // console.log(responseTestimonies.data);

                const responsePorto = await api.get('products?safety_key=M1n0r_P34c3_0f_C4l4mity&whitelabel=' + `${namapartner}`); // Ganti '/endpoint' dengan path API yang sesuai
                setResultPorto(responsePorto.data.data); // Mengatur hasil response ke state
                // console.log(responsePorto.data);

                const responseContactUs = await api.get('contact-us?safety_key=M1n0r_P34c3_0f_C4l4mity&whitelabel=' + `${namapartner}`); // Ganti '/endpoint' dengan path API yang sesuai
                setResultContactUs(responseContactUs.data.data); // Mengatur hasil response ke state
                // console.log(responseContactUs.data);

                const responseFaq = await api.get('faq?safety_key=M1n0r_P34c3_0f_C4l4mity&whitelabel=' + `${namapartner}`); // Ganti '/endpoint' dengan path API yang sesuai
                setResultFaq(responseFaq.data.data); // Mengatur hasil response ke state
                // console.log(responseFaq.data);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        // Panggil fungsi fetch saat komponen di-mount
        fetchData();
        window.scrollTo(0, 0)
    }, []); // Dependensi kosong agar hanya dipanggil sekali saat komponen di-mount

    return (
        <>
            {/* <Header logoUrl={`https://cmsfg.fitekservice.my.id/assets/images/aboutUs/${resultAboutUs.logo}`} namapartner={namapartner} /> */}
            {/* <div className='image-banner img-fluid'>
                <img src={`https://cmsfg.fitekservice.my.id/assets/images/partner/${result.file}`} alt='banner' width={1519} height={720} />
            </div> */}
            {/* <div className='banner-text poppins-bold' >

            </div>

            <AboutUs resultAboutUs={resultAboutUs}></AboutUs>
            
            <Strength resultAboutUs={resultAboutUs}></Strength>

            <Portfolio resultPorto={resultPorto} styles={styles} isMobile={isMobile}></Portfolio>

            <Testimony resultTestimonies={resultTestimonies}></Testimony>

            {resultAboutUs.visi && resultAboutUs.misi && ( <Visimisi resultAboutUs={resultAboutUs} styles={styles} isMobile={isMobile} />)}
            {resultArticles.name && ( <News resultArticles={resultArticles} /> )}

            <Faq resultFaq={resultFaq}></Faq>
            <ScrollToTop smooth />

            <Footer resultContactUs={resultContactUs} styles={styles}></Footer> */}
            <Navbar resultAboutUs={resultAboutUs}></Navbar>
            <Banner result={result} resultAboutUs={resultAboutUs}></Banner>
            <AboutUs resultAboutUs={resultAboutUs}></AboutUs>
            <Strength resultAboutUs={resultAboutUs} styles={styles}></Strength>
            <Portfolio resultPorto={resultPorto} styles={styles}></Portfolio>
            <News resultArticles={resultArticles} />
            <Testimony resultTestimonies={resultTestimonies} styles={styles}></Testimony>
            <VisionMission resultAboutUs={resultAboutUs} styles={styles} isMobile={isMobile}></VisionMission>
            <Faq resultFaq={resultFaq}></Faq>
            <Footer resultContactUs={resultContactUs} styles={styles}></Footer>
            
            <ScrollToTop className='flex flex-row justify-center items-center hover:transition-all hover:scale-125' smooth />
            <a target='_blank' href={`https://web.whatsapp.com/send?phone=${resultContactUs.mobile_phone}`} variant='success' className='waButton' style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: '9999', backgroundColor: 'green', borderRadius: '50%', width: '70px', height: '70px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight:"100px" }} id='whatsapp'>
                <FaWhatsapp size={40} color='white'/>
            </a>
        </>
    )
}

export default MembersLandingPage