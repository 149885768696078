// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

export default function Testimony({resultTestimonies, styles, en = false}){

  const isMobile = window.innerWidth < 758
  const testimonies = en ? "Testimonies" : "Testimoni";
  const FromColor = styles.primaryColor ? `from-[${styles.primaryColor}]` : `lg:from-blue-900`  

  return (
    <>
      <div id='testimony' className={`min-h-screen bg-gradient-to-t ${FromColor} from-50% to-transparant to-50% overflow-x-hidden`}
        style={{
          backgroundImage: `linear-gradient( transparent 50%, ${styles.primaryColor} 50%)`
        }}
      >
        <div data-aos="fade-left" className="h-full flex flex-col justify-center lg:w-5/6 lg:mx-auto">
          <h2 className="text-center font-bold text-3xl my-4">
            {testimonies}
          </h2>
          <div>
            <Swiper
              spaceBetween={50}
              slidesPerView={isMobile ? 1 : 3}
              // onSlideChange={() => console.log('slide change')}
              // onSwiper={(swiper) => console.log(swiper)}
            >
              {resultTestimonies.some(testimony => !!testimony.file) && (
                resultTestimonies.map((testimony, index) => (
                  <SwiperSlide>
                    <div className='h-96 bg-white shadow-2xl py-8'>
                      <div className='flex flex-col items-center justify-center gap-y-3 w-2/3 mx-auto '>
                        <img src={`https://cmsfg.fitekservice.my.id/assets/images/testimony/${testimony.file}`} alt="" className='aspect-square object-cover h-16 rounded-full'/>
                        <h2 className='font-bold text-3xl'>
                          {testimony.name}
                        </h2>
                        <p className='text-justify'>
                          {testimony.description}
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))
              )}
            </Swiper>
          </div>
        </div>
      </div>
    </>
  )
}