export default function PortfolioInformation({styles}){
  return (
    <>
    <div className="min-h-screen py-4" style={{backgroundColor: styles.primaryColor}}>
      <h2 className="text-3xl font-bold text-center mb-8 text-white"> Proyek Pengerjaan </h2>
      <div className="flex flex-col gap-y-4 w-4/5 mx-auto text-white" >
        <p className="text-justify">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maxime blanditiis excepturi alias esse autem doloribus a consequuntur eaque ipsa eos inventore, similique minus quaerat obcaecati necessitatibus reprehenderit vel! Delectus, dolorum harum! Voluptatum eaque saepe, aperiam numquam veritatis voluptas eligendi alias ratione eveniet dolorem. Obcaecati cupiditate voluptates expedita, autem eum sint.
        </p>
      </div>
    </div>
    </>
  )
}