import { Button, Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { useState } from 'react'

export default function Modal({porto, en, styles}) {
  let [isOpen, setIsOpen] = useState(false)

  function open() {
    setIsOpen(true)
  }

  function close() {
    setIsOpen(false)
  }

  return (
    <>
      <Button
        onClick={open}
        className=""
      >
        <img src={`https://cmsfg.fitekservice.my.id/assets/images/products/${porto.file}`} alt="" className='aspect-square object-cover hover:scale-125 hover:transition-all'/>
      </Button>

      <Dialog open={isOpen} as="div" className="relative z-10 focus:outline-none" onClose={close}>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <DialogPanel
              transition
              className="w-full max-w-md rounded-xl p-6 duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
              style={{
                backgroundColor: styles.primaryColor
              }}
            >
              <DialogTitle as="h3" className="text-3xl text-center mb-3 font-medium text-white">
                {porto.name}
              </DialogTitle>
              <img src={`https://cmsfg.fitekservice.my.id/assets/images/products/${porto.file}`} alt="" className='aspect-square object-cover'/>
              <p className="mt-2 text-sm/6 text-white/50" dangerouslySetInnerHTML={{__html: (en ? porto.description_en : porto.description) }}>
              </p>
              <div className="mt-4">
                <Button
                  className="inline-flex items-center gap-2 rounded-md  py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white "
                  onClick={close}
                  style={{
                    backgroundColor: styles.secondaryColor
                  }}
                >
                  Ok
                </Button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  )
}