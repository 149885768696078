import LinkedinImage from "../img/Compro FG/linkedin.svg"
import TwitterImage from "../img/Compro FG/twitter.svg"
import FacebookImage from "../img/Compro FG/facebook.svg"
import InstagramImage from "../img/Compro FG/instagram.svg"
import YoutubeImage from "../img/Compro FG/youtube.svg"
import LogoImage from "../img/Compro FG/Fitek white.png"

export default function Footer({resultContactUs, styles}){

  const InstagramLink = `https://www.instagram.com/${resultContactUs.instagram}`
  const TwitterLink = `https://www.x.com/${resultContactUs.twitter}`
  const LinkedinLink = `https://www.linkedin.com/in/${resultContactUs.linkedin}`

  const BgColor = styles.primaryColor ? `bg-[${styles.primaryColor}]` : `bg-blue-900`

  return (
    <>
      <div className={`${BgColor} py-8 flex flex-col gap-y-6 overflow-x-hidden`} style={{backgroundColor: styles.primaryColor}}>
        <div className="flex flex-row justify-center gap-x-3 items-center">
          <a href={InstagramLink} target="_blank" className="hover:opacity-50">
            <img src={InstagramImage} alt="" />
          </a>
          <a href={InstagramLink} target="_blank" className="hover:opacity-50">
            <img src={FacebookImage} alt="" />
          </a>
          <a href={LinkedinLink} target="_blank" className="hover:opacity-50">
            <img src={LinkedinImage} alt="" />
          </a>
          <a href={InstagramLink} target="_blank" className="hover:opacity-50">
            <img src={YoutubeImage} alt="" />
          </a>
          <a href={TwitterLink} target="_blank" className="hover:opacity-50">
            <img src={TwitterImage} alt="" />
          </a>
        </div>
        <div className="flex flex-row justify-center gap-x-3 items-center">
          <img src={LogoImage} alt="" />
        </div>
        <div className="flex flex-row justify-center gap-x-3 items-center">
          <p className="text-white">
            Copyright &copy; 2024 Fitek
          </p>
        </div>
      </div>
    </>
  )
}