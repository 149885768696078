import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

export default function Faq({resultFaq, en = false}){
  return (
    <>
      <div data-aos="fade-right" className="w-3/4 mx-auto py-8 overflow-x-hidden">
        <h2 className="text-center font-bold text-3xl">
          FAQ
        </h2>
        <div>
          {
              resultFaq.map(faq =>(
                  <Disclosure as="div" className="p-6" defaultOpen={false}>
                  <DisclosureButton className="group flex w-full items-center justify-between">
                    <span className="text-xl font-bold text-black group-data-[hover]:text-black/50 text-left">
                      {en ? faq.question_en : faq.question}
                    </span>
                    <hr />
                    <ChevronDownIcon className="size-5 fill-black/60 group-data-[hover]:fill-black/50 group-data-[open]:rotate-180" />
                  </DisclosureButton>
                  <DisclosurePanel className="mt-2 text-sm/5 text-black text-left">
                    {en ? faq.answer_en : faq.answer}
                  </DisclosurePanel>
                </Disclosure>
              ))
          }
        </div>
      </div>
    </>
  )
}